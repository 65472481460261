import React from 'react'
import styled from 'styled-components'

import { CompanyInfo } from '../constants'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .vacancies-wrapper {
    padding: 0 3rem 3rem 3rem;
  }

  p {
    text-align: left;
  }
`

const VacanciesPage = () => (
  <Wrapper>
    <div className="vacancies-wrapper">
      <h1>Наши вакансии</h1>
      <p>На летний сезон требуются:</p>
      <ul>
        <li>Горничные</li>
        <li>Хозяйственный работник</li>
        <li>Повара</li>
        <li>Официанты</li>
        <li>Посудомойщик</li>
        <li>Кухонный работник</li>
      </ul>
      <p>Всем сотрудникам предоставляется проживание и питание.</p>
      <p>
        <span>Резюме необходимо направлять на почту </span>
        <a href={`mailto:${CompanyInfo.email}?subject=Резюме`}>
          {CompanyInfo.email}
        </a>
        <span>.</span>
      </p>
    </div>
  </Wrapper>
)

export default VacanciesPage
